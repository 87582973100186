import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
} from "reactstrap";

const Scripts = ({ isLoading, initialData,updateScripts }) => {
  const {
    pag_googleConversionID,
    pag_googleConversionLabel,
    pag_googleMetaTag,
    pag_googleAnalyticsID,
    pag_MSVerificationTag,
    pag_MSUETTagID,
    pag_customHead,
    pag_customBody,
    pag_customFooter,
  } = initialData || {};

  // Decode the initial data
  const [googleConversionId, setGoogleConversionId] = useState(pag_googleConversionID || "");
  const [conversionLabel, setConversionLabel] = useState(pag_googleConversionLabel || "");
  const [googleMetaTag, setGoogleMetaTag] = useState(
    decodeURIComponent(window.atob(pag_googleMetaTag || ""))
  );
  const [googleAnalyticsId, setGoogleAnalyticsId] = useState(pag_googleAnalyticsID || "");
  const [msSiteVerificationTag, setMsSiteVerificationTag] = useState(
    decodeURIComponent(window.atob(pag_MSVerificationTag || ""))
  );
  const [msUetTagId, setMsUetTagId] = useState(pag_MSUETTagID || "");
  const [headScript, setHeadScript] = useState(
    decodeURIComponent(window.atob(pag_customHead || ""))
  );
  const [bodyScript, setBodyScript] = useState(
    decodeURIComponent(window.atob(pag_customBody || ""))
  );
  const [footerScript, setFooterScript] = useState(
    decodeURIComponent(window.atob(pag_customFooter || ""))
  );

  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const handleSave = async () => {
    updateScripts(googleConversionId,conversionLabel,window.btoa(encodeURIComponent(googleMetaTag)),googleAnalyticsId,window.btoa(encodeURIComponent(msSiteVerificationTag)),msUetTagId,window.btoa(encodeURIComponent(headScript)),window.btoa(encodeURIComponent(bodyScript)),window.btoa(encodeURIComponent(footerScript)));    
  };

  return (
    <div className={"styleDetails"}>
      <Accordion open={open} toggle={toggle} className={"mb-2"}>
        <AccordionItem>
          <AccordionHeader targetId="1">Google Scripts</AccordionHeader>
          <AccordionBody accordionId="1">
            <Form>
              <FormGroup>
                <Label for="GoogleConversionID">Google Conversion ID</Label>
                <Input
                  type={"text"}
                  value={googleConversionId}
                  onChange={(e) => setGoogleConversionId(e.target.value)}
                  placeholder={"Enter Google Conversion ID"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="conversionLabel">Conversion Label</Label>
                <Input
                  type={"text"}
                  value={conversionLabel}
                  onChange={(e) => setConversionLabel(e.target.value)}
                  placeholder={"Enter Conversion Label"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="googleMetaTag">Google Meta Tag</Label>
                <Input
                  type={"text"}
                  value={googleMetaTag}
                  onChange={(e) => setGoogleMetaTag(e.target.value)}
                  placeholder={"Enter Google Meta Tag"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="GoogleAnalyticsID">Google Analytics ID</Label>
                <Input
                  type={"text"}
                  value={googleAnalyticsId}
                  onChange={(e) => setGoogleAnalyticsId(e.target.value)}
                  placeholder={"Enter Google Analytics ID"}
                />
              </FormGroup>
            </Form>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="2">Microsoft Scripts</AccordionHeader>
          <AccordionBody accordionId="2">
            <Form>
              <FormGroup>
                <Label for="MSSiteVerificationTag">MS Site Verification Tag</Label>
                <Input
                  type={"text"}
                  value={msSiteVerificationTag}
                  onChange={(e) => setMsSiteVerificationTag(e.target.value)}
                  placeholder={"Enter MS Site Verification Tag"}
                />
              </FormGroup>
              <FormGroup>
                <Label for="MSUetTagId">MS UET Tag ID</Label>
                <Input
                  type={"text"}
                  value={msUetTagId}
                  onChange={(e) => setMsUetTagId(e.target.value)}
                  placeholder={"Enter MS UET Tag ID"}
                />
              </FormGroup>
            </Form>
          </AccordionBody>
        </AccordionItem>
        <AccordionItem>
          <AccordionHeader targetId="3">General Scripts</AccordionHeader>
          <AccordionBody accordionId="3">
            <Form>
              <FormGroup>
                <Label for="headScript">Head</Label>
                <Input
                  type={"textarea"}
                  value={headScript}
                  onChange={(e) => setHeadScript(e.target.value)}
                  placeholder={"Paste your Code here"}
                />
                <FormText className={"text-white"}>
                  Enter JavaScript or CSS code into the HTML document header.
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for="bodyScript">Body</Label>
                <Input
                  type={"textarea"}
                  value={bodyScript}
                  onChange={(e) => setBodyScript(e.target.value)}
                  placeholder={"Paste your Code here"}
                />
                <FormText className={"text-white"}>
                  Enter HTML, JavaScript, or CSS right after the opening body tag.
                </FormText>
              </FormGroup>
              <FormGroup>
                <Label for="footerScript">Footer</Label>
                <Input
                  type={"textarea"}
                  value={footerScript}
                  onChange={(e) => setFooterScript(e.target.value)}
                  placeholder={"Paste your Code here"}
                />
                <FormText className={"text-white"}>
                  Enter HTML, JavaScript, or CSS right before the closing body tag.
                </FormText>
              </FormGroup>
            </Form>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
      <Button className={"primary-btn-modal shadow-none"} onClick={handleSave}>
        {isLoading ? (
          <span className={"spinnerContainer"}>
            {" "}
            Saving <Spinner>Loading...</Spinner>
          </span>
        ) : (
          "Save"
        )}
      </Button>{" "}
    </div>
  );
};

export default Scripts;
