import { Chip, Dialog, DialogContent, Tooltip } from "@material-ui/core";
import { Grid, Icon, InputAdornment, IconButton, CircularProgress } from "@mui/material";
import client from "ApiClient";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useCallback, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

export default function Templates(props) {
  const { open, filterPageId } = props;
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const [searchPage, setSearchPage] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const [templatesOriginal, setTemplatesOriginal] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [filter, setFilter] = useState([]);

  const options = {
    method: "GET",
    url: "pages",
    headers: {
      "content-type": "application/json",
    },
  };

  const getPages = async () => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    options.method = "GET";
    options.url = `pages?page=${page}&limit=10&search=${searchPage}`;
    try {
      await client
        .request(options)
        .then((response) => {
          if (response.length > 0) {
            const pages = filterPageId
              ? response.filter((p) => p.pag_id != filterPageId)
              : response;

            page == 0 ? setItems(pages) : setItems((prevItems) => [...prevItems, ...pages]);
            setPage((prevPage) => prevPage + 1);
          } else {
            setHasMore(false); // Si no hay más datos, dejamos de cargar
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsSearching(false);
    }
  };

  const getTemplates = async () => {
    options.method = "GET";
    options.url = `pages/templates`;
    await client
      .request(options)
      .then((response) => {
        const tem = response.reverse();
        setTemplates(tem);
        setTemplatesOriginal(tem);
        let categories = response.filter((f) => f.pat_category != "").map((p) => p.pat_category);
        categories = [...new Set(categories)];

        setFilter(categories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterTemplates = (f) => {
    if (f == "All") setTemplates(templatesOriginal);
    else setTemplates(templatesOriginal.filter((t) => t.pat_category == f));
  };

  const handleScroll = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollRatio = scrollTop / (scrollHeight - clientHeight);
    if (scrollRatio > 0.5 && !isLoading && activeTab == "2") {
      getPages();
    }
  };

  useEffect(() => {
    getTemplates();
    getPages();
    return () => {
      setItems([]);
    };
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const search = () => {
    setPage(0);
    setHasMore(true);
    setItems([]);
    setIsSearching(true);
  };

  useEffect(() => {
    getPages();
  }, [isSearching]);

  return (
    <Dialog open={open} onClose={(e, reason) => props.close()} fullScreen>
      <MDBox
        variant="gradient"
        bgColor="secondary"
        coloredShadow="secondary"
        mx={2}
        mt={-1}
        p={2}
        mb={1}
        textAlign="center"
      >
        <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
          Select a design to start from template or clone existing page
        </MDTypography>
      </MDBox>
      <Nav justified pills tabs style={{ marginBottom: 20 }}>
        <NavItem>
          <NavLink
            className={activeTab === "1" ? "active" : ""}
            onClick={() => {
              toggle("1");
            }}
          >
            Templates
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => {
              toggle("2");
            }}
          >
            Clone Page
          </NavLink>
        </NavItem>
      </Nav>
      <DialogContent onScroll={handleScroll}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <MDBox textAlign="center" mb={2}>
              {filter && (
                <Grid item xs={12}>
                  <Chip
                    label="All"
                    onClick={() => filterTemplates("All")}
                    style={{ marginRight: 10, marginBottom: 10 }}
                  />
                  {filter.map((f, index) => {
                    return (
                      <Chip
                        key={index}
                        label={f}
                        onClick={() => filterTemplates(f)}
                        style={{ marginRight: 10, marginBottom: 10 }}
                      />
                    );
                  })}
                </Grid>
              )}
            </MDBox>
            <Grid container spacing={10} style={{ marginTop: 20 }}>
              <Grid item xs={12} sm={6} md={4} xl={4}>
                <MDBox
                  position="relative"
                  borderRadius="lg"
                  mt={-3}
                  mx={2}
                  className="card-header"
                  textAlign="center"
                >
                  <MDBox
                    component="img"
                    src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/templates/images/blank.png`}
                    alt="Blank"
                    width="100%"
                    height="100%"
                    borderRadius="lg"
                    shadow="md"
                    position="relative"
                    style={{ cursor: "pointer" }}
                    zIndex={1}
                    onClick={() => {
                      props.close();
                    }}
                  />
                </MDBox>
                <MDBox
                  textAlign="center"
                  pt={3}
                  px={3}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props.loadTemplate(JSON.parse(template.pat_json));
                  }}
                >
                  <MDTypography variant="h6" fontWeight="medium" mt={1}>
                    Blank
                  </MDTypography>
                </MDBox>
              </Grid>

              {templates.map((template, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} xl={4}>
                    <MDBox
                      position="relative"
                      borderRadius="lg"
                      mt={-3}
                      mx={2}
                      className="card-header"
                      style={{ cursor: "pointer" }}
                    >
                      <MDBox
                        component="img"
                        src={`https://thumbs.${process.env.REACT_APP_MAIN_DOMAIN}/templates/images/${template.pat_slug}_thumbnail.jpg`}
                        alt={template.pat_name}
                        borderRadius="lg"
                        shadow="md"
                        width="100%"
                        height="100%"
                        position="relative"
                        zIndex={1}
                        onClick={() => {
                          props.loadTemplate(JSON.parse(template.pat_json));
                        }}
                      />
                    </MDBox>
                    <MDBox
                      textAlign="center"
                      pt={3}
                      px={3}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.loadTemplate(JSON.parse(template.pat_json));
                      }}
                    >
                      <MDTypography variant="h6" fontWeight="medium" mt={1}>
                        {template.pat_name}
                      </MDTypography>
                    </MDBox>
                  </Grid>
                );
              })}
            </Grid>
          </TabPane>
          <TabPane tabId="2">
            {isSearching ? (
              <MDBox textAlign="center" mt={10}>
                <CircularProgress />
              </MDBox>
            ) : items.length == 0 && !isLoading && !hasMore ? (
              <MDBox textAlign="center">
                <MDInput
                  style={{ padding: 10, width: 400 }}
                  type="text"
                  label="Search"
                  value={searchPage}
                  onChange={(e) => setSearchPage(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      search();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <MDBox display="flex" flexDirection="row">
                          <IconButton
                            onClick={() => {
                              search();
                            }}
                            sx={{ fontWeight: "bold" }}
                            color="info"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Search">
                              <Icon fontSize="small">search</Icon>
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setSearchPage("");
                              search();
                            }}
                            sx={{ fontWeight: "bold" }}
                            color="error"
                            aria-label="prompt"
                          >
                            <Tooltip id="button-report" title="Clear">
                              <Icon fontSize="small">close</Icon>
                            </Tooltip>
                          </IconButton>
                        </MDBox>
                      </InputAdornment>
                    ),
                  }}
                />
                <MDTypography>No pages found</MDTypography>
              </MDBox>
            ) : (
              <>
                <MDBox textAlign="center" mb={10}>
                  <MDInput
                    style={{ padding: 10, width: 400 }}
                    type="text"
                    label="Search"
                    value={searchPage}
                    onChange={(e) => setSearchPage(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        search();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <MDBox display="flex" flexDirection="row">
                            <IconButton
                              onClick={() => {
                                search();
                              }}
                              sx={{ fontWeight: "bold" }}
                              color="info"
                              aria-label="prompt"
                            >
                              <Tooltip id="button-report" title="Search">
                                <Icon fontSize="small">search</Icon>
                              </Tooltip>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSearchPage("");
                                search();
                              }}
                              sx={{ fontWeight: "bold" }}
                              color="error"
                              aria-label="prompt"
                            >
                              <Tooltip id="button-report" title="Clear">
                                <Icon fontSize="small">close</Icon>
                              </Tooltip>
                            </IconButton>
                          </MDBox>
                        </InputAdornment>
                      ),
                    }}
                  />
                </MDBox>
                <Grid container spacing={10} tyle={{ marginTop: 20 }}>
                  {items.map((page, index) => {
                    return (
                      <Grid key={index} item xs={12} sm={6} md={4} xl={4}>
                        <MDBox
                          position="relative"
                          borderRadius="lg"
                          mt={-3}
                          mx={2}
                          className="card-header"
                          style={{ cursor: "pointer" }}
                        >
                          <MDBox
                            component="img"
                            src={page.pag_thumbnailURL}
                            alt={page.pag_name}
                            borderRadius="lg"
                            shadow="md"
                            width="100%"
                            height="100%"
                            position="relative"
                            zIndex={1}
                            onClick={() => {
                              props.loadPage(page.pag_id);
                            }}
                          />
                        </MDBox>
                        <MDBox
                          textAlign="center"
                          pt={3}
                          px={3}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            props.setPage(page.pag_id);
                            setOpenPages(false);
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium" mt={1}>
                            {page.pag_name}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            )}
          </TabPane>
        </TabContent>
      </DialogContent>
    </Dialog>
  );
}
